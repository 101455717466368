import React from "react";
import {Route, Routes} from "react-router-dom";
import PublicRoutes from "./Routes/PublicRoutes";


export default function Rutas() {
    return (
        <Routes>
            <Route path="/*" element={<PublicRoutes/>} />
        </Routes>
    );
}
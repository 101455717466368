import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Tilt from 'react-parallax-tilt';
import '../../Styles/Public/landing.scss';
import 'animate.css/animate.min.css';
import Testimonios from './Testimonios';
import CounterAnimated from '../Widgets/CounterAnimated';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";


const { Text, Title } = Typography


export default function LiberaIngles(props) {

    return (
        <Layout className="mt-3">
            <div id="about-us"></div>
            <Row justify={"center"} >
                <Content className="ml-2 mt-3 mr-2 mb-2 max-1400">
                    <Row justify={"center"} className="mb-2">
                        <Text className="landing-title-2">
                            ¡Libera tu inglés!
                        </Text>
                    </Row>
                    <Row>
                        <Text className="landing-content">
                            Así como lo lees; directo, fácil y rápido.<br /><br />
                            Prepárate para los exámenes de 4 Habilidades en IPN, Comprensión de Lectura en ENALLT y Examen Global en UNAM.
                        </Text>
                    </Row>
                </Content>
            </Row>
            <Content className="divisor-img">
                <Row className="pt-1 pb-1 max-1400" justify={"center"}>
                    <Col xs={24} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            <CounterAnimated contador={1500} tiempo={0.7} />
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            Constancias para la IPN
                        </Row>
                    </Col>
                    <Col xs={24} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            <CounterAnimated contador={800} tiempo={0.7} />
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            para la UNAM examen global
                        </Row>
                    </Col>
                    <Col xs={24} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            <CounterAnimated contador={500} tiempo={0.7} />
                            {/* ¡Más de <CounterAnimated contador={1000} tiempo={0.7}/> Pumas ya titulados! */}
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            para compresión de lectura
                        </Row>
                    </Col>
                    <Col xs={24} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            <CounterAnimated contador={200} tiempo={0.7} />
                            {/* ¡Más de <CounterAnimated contador={1000} tiempo={0.7}/> Pumas ya titulados! */}
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            para incorporadas
                        </Row>
                    </Col>
                </Row>
            </Content>
            <Content className="ml-2 mt-3 mr-2 mb-2 max-1400">
                <Row className="center">
                    <Text className="landing-content">
                        Esta es tu apuesta segura para ser parte del porcentaje que ha concluído sus estudios profesionales satisfactoriamente. Estas son tus 3 opciones:
                    </Text>
                </Row>
                <Row justify={"center"} className="mt-2" gutter={[0, 20]}>
                    <Col xs={24} md={12} lg={8}>
                        <Row justify={"center"}>
                            <Link to="/cursos/acreditacion">
                                <Tag className="info-tag">
                                    IPN, UAM e incorporadas
                                </Tag>
                            </Link>

                        </Row>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Row justify={"center"}>
                            <Link to="/cursos/comprension-lectora">
                                <Tag className="info-tag">
                                    Comprensión Lectora en UNAM
                                </Tag>
                            </Link>
                        </Row>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Row justify={"center"}>
                            <Link to="/cursos/examen-global-unam">
                                <Tag className="info-tag">
                                    Examen Global en UNAM
                                </Tag>
                            </Link>
                        </Row>
                    </Col>
                </Row>
            </Content>
            <Content className="divisor-img mt-3" >
                <Row className="pt-1 pb-1 max-1400" justify={"center"}>
                    <Col xs={24}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            ¿Y si no sabes ingles?
                        </Row>
                    </Col>
                </Row>
            </Content>
            <Content className="ml-2 mt-3 mr-2 mb-2 max-1400">
                <Row className="center">
                    <Text className="landing-content">
                        ¡También te ayudamos a que lo aprendas y a que lo hables!
                    </Text>
                </Row>
                <Row justify={"center"} className="mt-2" gutter={[0, 20]}>
                    <Col xs={24} md={12} lg={8}>
                        <Row justify={"center"}>
                            <Link to="/cursos/basico">
                                <Tag className="info-tag">
                                    Curso Básico Integral
                                </Tag>
                            </Link>
                        </Row>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Row justify={"center"}>
                            <Link to="/cursos/speak-king">
                                <Tag className="info-tag">
                                    Speak-king
                                </Tag>
                            </Link>
                        </Row>
                    </Col>
                </Row>
            </Content>
            <Content>
                <Row justify={"center"} className="mt-2 landing-content" style={{ fontSize: 36, fontWeight: "bold" }}>
                    ¿Son tan buenos?
                </Row>
                <Row justify={"center"} className="mt-2 landing-content">
                    Juzga por ti mismo con los comentarios de los que ya forman parte de la familia IM
                </Row>
            </Content>
            <Row className="mt-2">
                <Testimonios />
            </Row>
        </Layout>
    )

}



import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Tilt from 'react-parallax-tilt';
import '../../Styles/Public/landing.scss';


import 'animate.css/animate.min.css';


import Testimonios from './Testimonios';


const { Text, Title } = Typography


export default function CursoBasico(props) {



    return (
        <>
            <Layout className="mt-2">

                <Row justify={"center"} >
                    <Content className="mt-2 bg-landing center" style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                        <div className="max-1400">
                            <Row justify={"center"} className="mb-2">
                                <Text className="landing-title">
                                    Curso Básico
                                </Text>
                            </Row>
                            <Row justify={"center"}>
                                <Text className="landing-message">
                                    En este curso hemos enfocado la innovación. Si vas a cumplir tus metas en un idioma que sean claras, realistas, y sobre todo las que estás buscando. <br /><br />
                                    Habla y escribe desde la primera clase. Olvídate de los cursos de pura gramática o libros al por mayor. Invierte bien tu tiempo y dinero para que vivas el idioma y lo vivas de forma actualizada. <br /><br />
                                    Durante 12 módulos te llevaremos de ‘cero’ a poder conversar de manera casual.
                                </Text>
                            </Row>
                        </div>

                    </Content>
                    <Content className="ml-2 mt-2 mr-2 mb-2 max-1400">

                        {/* <Row justify={"center"} className="mb-2">
                            <Text className="landing-title-2">
                                **What’s up!! Somos Inglés a la Mexicana**
                            </Text>
                        </Row> */}
                        <Row align={"middle"}>
                            <Col xs={24} md={12}>
                                <Row justify={"center"}>
                                    <Tilt tiltReverse="true" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                        <img src="https://images.pexels.com/photos/159581/dictionary-reference-book-learning-meaning-159581.jpeg" className="width-100" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} alt="group" />
                                    </Tilt>

                                </Row>

                            </Col>
                            <Col xs={24} md={12} className="height-100">

                                <Row>
                                    <Text className="landing-message-2 mt-2 pd-3">
                                        <ul className="puntos-clave">
                                            <li>
                                                18 horas por módulo.
                                            </li>
                                            <li>
                                                Todo el material incluído
                                            </li>
                                            <li>
                                                Enfoque en writing y Speaking
                                            </li>
                                            <li>
                                                Seguimiento  personalizado
                                            </li>
                                            <li>
                                                Conversación cada clase.
                                            </li>
                                            <li>
                                                Corrección de pronunciación.
                                            </li>
                                        </ul>
                                    </Text>
                                </Row>
                                <Row justify={"center"}>

                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Row>
                <Row className="divisor-img pt-1 pb-1" gutter={{ xs: [32,32], sm: [32,32], md: 24, lg: 32 }}>
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            18
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            horas x modulo
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            Habla
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            y escribe ingles desde el incio
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            Profesores
                        </Row>
                        <Row style={{ textAlign: "center" }} justify={"center"} className="cursos-divisor-subtitle">
                            Lics. y Certificados
                            {/* y escribe ingles desde el incio */}
                        </Row>
                        {/* <Row justify={"center"} className="cursos-divisor-subtitle">
                        </Row> */}
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            Grupos
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            Reducidos
                        </Row>
                    </Col>
                </Row>



                <Content justify={"center"} className="ml-2 mt-3 mr-2 mb-2">


                    <Row justify={"center"}>
                        <Text className="landing-title-3">
                            ¿De qué va este curso?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Este fue diseñado con todo el cuidado del mundo para hacer que los mexicanos hablen el idioma. Tomamos lo mejor de muchas investigaciones y métodos y nos enfocamos en las estrategias que te hacen entender y HABLAR desde nivel básico o módulo 1.
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Quién me enseña?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Un verdadero especialista en la enseñanza de inglés, egresado de la Licenciatura en Enseñanza de Inglés (UNAM). Que hace que la clase sea dinámica y digerible para que puedas disfrutar de los beneficios reales con cada módulo que avanzas.

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Puede entrar cualquiera?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Sí, y si es tu primer acercamiento o estás buscando algo innovador, este curso es tu mejor decisión. <br />
                            Si ya tienes conocimiento, también puedes entrar para reforzar el conocimiento que ya tienes e incrementarlo.  La prueba te coloca en el módulo adecuado para ti.

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-2">
                            HORARIOS DISPONIBLES
                        </Text>
                    </Row>
                    <Row justify={"center"} className="mt-2 mb-2">
                        <p style={{ textAlign: "center" }}>
                            Lunes, miércoles y jueves<br />
                            20:00 a 21:30 h<br />
                        </p>
                    </Row>

                    <Row justify={"center"}>
                        <a href="http://m.me/IngsalaMex?text=Quiero%20reservar%20mi%20lugar%20para%20curso%20integral" target="_blank" rel="noreferrer">
                            <Tag className="info-tag">
                                Reservar mi lugar
                            </Tag>
                        </a>

                    </Row>

                    <Row>
                        <Testimonios />
                        {/* <OwlCarousel className='owl-theme max-1400 mt-2' loop items={1} margin={10} dots={true} dotsClass="custom-dots width-100 center">
                            {[
                                {
                                    url: '/img/testimonios/lizzete_solis.jpg',
                                    nombre: 'Lizette Solís',
                                    curso: 'Básico integral',
                                    opinion: <>
                                        Es una muy buena opción para acercarte al idioma, en mi experiencia tomándolo desde el nivel básico te brindan un ambiente de seguridad y confianza para que te sientas seguro al esta desarrollando nuevas habilidades en el idioma, las clases son tan dinámicas que no se te complica el aprender.  <br />
                                        Son súper amables y atentos, siempre a tu disposición para aclarar dudas.
                                    </>
                                },
                                {
                                    url: "/img/testimonios/oswaldo_cedillo.jpg",
                                    nombre: 'Oswaldo Cedillo',
                                    curso: 'Speaking',
                                    opinion: <>
                                        El curso de Speaking que ofrecen, es muy dinámico y util. El tiempo se pasa demasiado rápido y no es para nada tedioso. Se ve bastante vocabulario y en ocasiones un poco de gramatica para poder conectar las ideas que se están hablando. <br />
                                        El profesor Arturo hace las clases bastante agradables y muy divertidas, además de que te motiva y ayuda a estructurar las ideas que quieres decir. Ningúna clase fue aburrida con el. Realmente recomiendo el curso y al profesor.

                                    </>
                                },
                                {
                                    url: '/img/testimonios/gaby_morales.jpg',
                                    nombre: 'Gaby Morales',
                                    curso: 'Comprensión de Lectura UNAM',
                                    opinion: <>
                                        Soy de la Fes Iztacala y tomar el curso de comprensión lectora me ayudó mucho para acreditación de inglés 😊
                                        El profesor está muy preparado, las estrategias son útiles y los métodos de enseñanza me gustaron bastante, siempre estaba abierto para resolver dudas con respeto y paciencia ☁️lo cual me hizo sentir muy cómoda para aprender. Sin duda recomiendo mucho este curso!
                                    </>
                                },

                                {
                                    url: "/img/testimonios/gabriela_vargas.jpg",
                                    nombre: 'Gabriela Vargas',
                                    curso: 'Plan Global, UNAM',
                                    opinion: <>
                                        Muy buen curso, presenté el examen en la FCA y logré acreditarlo gracias a las clases, ejercicios y recomendaciones de la teacher Dany. ✨ <br />
                                        La atención en todo momento de Dany y Gus es excelente!
                                    </>
                                },
                                {
                                    url: "/img/testimonios/orlando_corona.jpg",
                                    nombre: 'Orlando Corona',
                                    curso: 'Examen 4 habilidades, IPN',
                                    opinion: <>
                                        Soy de Upiicsa y el curso que ofrecen es bueno , te enseñan lo necesario para pasar el examen de 4 habilidades. <br />
                                        El profesor que me toco fue muy paciente con nosotros, explicaba de la mejor manera. Nos ponía ejercicios para cada módulo del examen y nos daba tips para poder mejorar en cada aspecto, también te orientan en el proceso del examen. Presente el examen y obtuve una buena calificación.
                                        <br />
                                        Recomiendo ampliamente el curso ✨

                                    </>
                                },

                            ].map(({ url, curso, opinion, nombre }) => <div className='item center'>
                                <Card className="card-opinion">
                                    <Row>
                                        <Col xs={24} md={12} lg={7} className="center width-100">
                                            <Row justify={"center"}>
                                                <Avatar size={{ xs: 150, sm: 200, md: 200, lg: 200, xl: 250, xxl: 250 }} src={url} />
                                            </Row>
                                            <Row justify={"center"} className="mt-2">
                                                <Text className="nombre">{curso}</Text>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={12} lg={17}>
                                            <Row>
                                                <Text className="opinion">{opinion}</Text>
                                            </Row>
                                            <Row className="mt-2">
                                                <Tag className="tag-nombre">{nombre}</Tag>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>)}
                        </OwlCarousel> */}
                    </Row>



                </Content>



            </Layout>
        </>

    )

}



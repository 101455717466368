import React, { useState, useEffect } from 'react';

const ContadorComponente = ({ tiempo, contador }) => {
    const [numeroVeces, setNumeroVeces] = useState(0);

    useEffect(() => {
        let intervalo;
        const startTime = new Date().getTime();

        const actualizarContador = () => {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - startTime;
            const numVeces = Math.floor(elapsedTime / tiempo);

            if (numVeces <= contador) {
                setNumeroVeces(numVeces);
            } else {
                setNumeroVeces(contador);
                clearInterval(intervalo);
            }
        }
        intervalo = setInterval(actualizarContador, tiempo);

        return () => {
            clearInterval(intervalo);
        };
    }, [tiempo, contador]);

    return numeroVeces.toLocaleString ()
};

export default ContadorComponente;
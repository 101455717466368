import { Header } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import '../../Styles/Public/header.scss';
import { Row, Col, Menu, Button, Drawer } from "antd";
import { useNavigate, useLocation } from "react-router-dom";


export default function DrawerPublic(props) {

    const { onClose } = props

    const navigate = useNavigate()
   

    const items = [
        {
            label: "Inicio",
            key: '/'
        },
        {
            label: "Conócenos",
            key: '/cursos/libera-tu-ingles'
        },
        {
            label: "Cursos",
            key: '/#courses'
        },
        {
            label: "Horarios",
            key: '/#schedules'
        }
    ]

    let handleClick = (e) => {


        navigate(e.key)
        onClose(false)

    }

    return (
        <div className="header-public">
            <Drawer 
                {...props}
            >
                <Row>
                    <Menu mode="vertical" className="width-100" items={items} onClick={e => handleClick(e)} />
                </Row>
            </Drawer>
        </div>

    )
}
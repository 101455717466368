const Cursos = [
    {
        title: 'Acreditación IPN, UAM e incorporadas.',
        description: <p>
            ¿Quieres disfrutar de un mejor salario, ser seleccionado más rápido en los empleos  y que tu mamá ponga tu foto en la pared de la sala para presumirle a todos los que entran a casa?
            <br />
            Hora de darte el CURSO DE ACREDITACIÓN DE IDIOMA INGLÉS
            <br />
            <br />
            <p style={{ textAlign: 'center' }}>
                <strong>Lunes a Jueves</strong> <br />
                <strong>Matutino: </strong>10:00 a 12:00 h<br />
                <strong>Vespertino: </strong>20:00 a 22:00 h<br />
                <strong>Sabados y Domingos</strong><br />
                10:00 a 12:30 h
            </p>
        </p>,
        image: '/img/cursos/lpm.jpg',
        color: '#1051C5',
        horarios: [
            {
                title: 'Matutino',
                start: '10:00:00',
                end: '12:00:00',
                weekdays: [0, 1, 2, 3]
            },
            {
                title: 'Vespertino',
                start: '20:00:00',
                end: '22:00:00',
                weekdays: [0, 1, 2, 3]
            },
            {
                title: 'Fines de Semana',
                start: '10:00:00',
                end: '12:30:00',
                weekdays: [5, 6]
            },
        ]
    },
    {
        title: 'Curso para Comprensión Lectora UNAM.',
        description: <p>
            Ya es hora de que tu título sea presumido por toda tu familia, licenciad@.<br />
            Lleva tu comprensión de lectura a otro nivel.
            <br />
            <br />
            <p style={{ textAlign: 'center' }}>
                <strong>Lunes, Miércoles y Jueves</strong> <br />
                20:00 a 22:00 h
            </p>
        </p>,
        image: '/img/cursos/comprension.jpg',
        color: '#2BD073',
        horarios: [
            // id: '1',
            // calendarId: '1',
            // title: 'Matemáticas',
            // category: 'time',
            {
                title: '',
                start: '20:00:00',
                end: '22:00:00',
                weekdays: [0, 2, 3]
            },
        ]
    },
    {
        title: 'Acreditación UNAM (Dominio Global).',
        description: <p>¿Estás list@ para un mejor salario, un mejor puesto y verte ultra mameitor en tu foto del título?
            <br />
            Libera tu inglés con el examen de dominio de la facultad de contaduría.
            <p style={{ textAlign: 'center' }}>
                <strong>Lunes a Jueves</strong> <br />
                20:00 a 22:00 h
            </p>
        </p>,
        image: '/img/cursos/plan_global.jpg',
        color: '#459EFF',
        horarios: [
            {
                title: '',
                start: '20:00:00',
                end: '22:00:00',
                weekdays: [0, 1, 2, 3]
            },
        ]
    },
    {
        title: 'Básico integral',
        description: <>
            <p>En este curso hemos enfocado la innovación. Si vas a cumplir tus metas en un idioma que sean claras, realistas, y sobre todo las que estás buscando.</p>
            <p style={{ textAlign: 'center' }}>
                Lunes, miércoles y jueves<br />
                20:00 a 21:30 h<br />
            </p>
        </>
        // `<p>
        //  </br></br>
        // Habla y escribe desde la primera clase. Olvídate de los cursos de pura gramática o libros al por mayor. Invierte bien tu tiempo y dinero para que vivas el idioma y lo vivas de forma actualizada. </br></br>
        // Durante 12 módulos te llevaremos de ‘cero’ a poder conversar de manera casual. 
        // </p>`
        ,
        image: 'https://images.pexels.com/photos/159581/dictionary-reference-book-learning-meaning-159581.jpeg',
        color: '#2BD073',
        horarios: [
            {
                title: '',
                start: '20:00:00',
                end: '21:30:00',
                weekdays: [0, 2, 3]
            },
        ]
    },

    {
        title: 'Speak-king',
        description: <p>¿Necesitas HABLAR inglés EN el trabajo para mejorar tu salario?<br />
            Vuélvete el rey de la conversación en 4 meses.<br />
            Disfruta del único curso conversacional en México, diseñado para que tu producción y precisión al hablar sea perfecta. No más miedo o pena de hablar en inglés.

            <br />
            En 4 módulos haremos que hables como en tu serie favorita.
            <p style={{ textAlign: 'center' }}>
                Lunes, miércoles y jueves<br />
                20:00 a 21:30 h<br />
            </p>

        </p>,
        image: 'https://images.pexels.com/photos/3719037/pexels-photo-3719037.jpeg',
        color: '#459EFF',
        horarios: [
            {
                title: '',
                start: '20:00:00',
                end: '21:30:00',
                weekdays: [0, 2, 3]
            },
        ]
    },
    {
        title: 'Inglés a tu medida',
        description: <p>¿Andas en busca de una experiencia muuuy personalizada?<br />Acá, como un buen traje, todo a la medida.<br /><br />
            Si tienes un objetivo específico con el idioma y quieres toda la atención para ti solo, este curso es tu aliado perfecto. <br />
            Todo se gestiona para que cumplas tus planes en tiempo record y cara a cara con el profesor, a tus tiempos y con tus condiciones.
        </p>,
        image: 'https://images.pexels.com/photos/5311446/pexels-photo-5311446.jpeg?cs=srgb&dl=pexels-karolina-grabowska-5311446.jpg',
        color: '#2BD073',
        horarios: [
            // id: '1',
            // calendarId: '1',
            // title: 'Matemáticas',
            // category: 'time',
            // {
            //     title: 'Matutino',
            //     start: '14:00:00',
            //     end: '16:00:00',
            //     weekdays: [0,1,2,3]
            // },
        ]
    },
    {
        title: 'Inglés para empresas',
        description: <p>¿Servicio a domicilio? Obvi. :D <br /><br />
            ¿Deseas que tus colaboradores aprovechen todos los beneficios que tiene esta hermosa herramienta? Te ayudamos a que su relación con el inglés sea la perfecta para que el negocio se vaya hasta arriba. </p>,
        image: 'https://images.pexels.com/photos/3182781/pexels-photo-3182781.jpeg',
        color: '#1051C5',
        horarios: [
            // id: '1',
            // calendarId: '1',
            // title: 'Matemáticas',
            // category: 'time',
            // {
            //     title: 'Matutino',
            //     start: '14:00:00',
            //     end: '16:00:00',
            //     weekdays: [0,1,2,3]
            // },
        ]
    },
]


const Preguntas = [

    {
        pregunta: 'The big book is _______',
        respuestas: ['mine', 'for my', 'me'],
        respuesta: 'mine'
    },
    {
        pregunta: '_________ a man working in the street',
        respuestas: ['There were', 'There are', 'There is'],
        respuesta: 'There is'
    },
    {
        pregunta: 'My uncle visits _________ every week.',
        respuestas: ['me', 'my', 'mine'],
        respuesta: 'me'
    },
    {
        pregunta: 'Jane is playing _________ the park.',
        respuestas: ['to', 'in', 'from'],
        respuesta: 'in'
    },
    {
        pregunta: 'Jim _________.',
        respuestas: ['be tall', 'tall', 'is tall'],
        respuesta: 'is tall'
    },
    {
        pregunta: 'What happened to all the apples? They _________ by birds.',
        respuestas: ['have got to eat', 'got eaten', 'have eaten'],
        respuesta: 'got eaten'
    },
    {
        pregunta: 'Please sit on _________ chair.',
        respuestas: ['those', 'that', 'them'],
        respuesta: 'that'
    },
    {
        pregunta: 'The dinner was _________ good.',
        respuestas: ['really', 'too much', 'great'],
        respuesta: 'really'
    },
    {
        pregunta: 'The sick boy _________ stay in bed.',
        respuestas: ['has got to', 'is got to', 'is getting'],
        respuesta: 'has got to'
    },
    {
        pregunta: 'There aren’t _________ in our street.',
        respuestas: ['some dogs', 'any dogs', 'any dog'],
        respuesta: 'any dogs'
    },
    {
        pregunta: 'Jil _________ her ice cream.',
        respuestas: ['has already finished.', 'has yet finished.', 'has finished already.'],
        respuesta: 'has already finished.'
    },
    {
        pregunta: 'The film is _________ the book.',
        respuestas: ['more interesting than', 'more interesting for', 'more interesting from'],
        respuesta: 'more interesting than'
    },
    {
        pregunta: '“Mother, _________ to bed?” asked John.',
        respuestas: ['have I got going', 'have I got to going', 'have I got to go'],
        respuesta: 'have I got to go'
    },
    {
        pregunta: 'Look at _________ birds in the trees.',
        respuestas: ['that', 'those', 'this'],
        respuesta: 'those'
    },
    {
        pregunta: '_________ sweater is yours? The green one, or the red one?',
        respuestas: ['Which', 'What', 'Whose'],
        respuesta: 'Which'
    },
    {
        pregunta: 'John is standing _________ the bus stop.',
        respuestas: ['to', 'in', 'at'],
        respuesta: 'at'
    },
    {
        pregunta: 'John _________ but we’ll start without him.',
        respuestas: ['hasn’t arrived since', 'hasn’t arrived yet', 'hasn’t already arrived'],
        respuesta: 'hasn’t arrived yet'
    },
    {
        pregunta: 'The man ran _________ the big dog.',
        respuestas: ['for get', 'to get', 'for getting'],
        respuesta: 'to get'
    },
    {
        pregunta: 'Jane doesn’t like _________ salt in her food.',
        respuestas: ['too many', 'many', 'much'],
        respuesta: 'much'
    },
    {
        pregunta: 'Do you think that our friends _________ for us? In any case, let’s hurry!',
        respuestas: ['wait', 'may be waiting', 'shall be waiting'],
        respuesta: 'may be waiting'
    },
    {
        pregunta: 'Barry’s real triumph is _________ in the desert for a month.',
        respuestas: ['to have survived', 'as to surviving', 'for surviving'],
        respuesta: 'to have survived'
    },
    {
        pregunta: 'We suggest _________ a report at once.',
        respuestas: ['the secretary writing', 'the secretary to write', 'the secretary write'],
        respuesta: 'the secretary write'
    },
    {
        pregunta: 'Mary, you really _________ the principal’s office while he was on holiday.',
        respuestas: ['shouldn’t be using', 'shouldn’t have been using', 'shouldn’t have been to use'],
        respuesta: 'shouldn’t have been using'
    },
    {
        pregunta: 'My grandmother fell on the street, but a kindly _________ helped her.',
        respuestas: ['helper', 'goer', 'pedestrian'],
        respuesta: 'pedestrian'
    },
    {
        pregunta: 'The decision _________ early was the correct one.',
        respuestas: ['to start', 'for a start', 'in starting'],
        respuesta: 'to start'
    },
    {
        pregunta: 'The woman touched the button, _________ the door opened.',
        respuestas: ['directly', 'whereupon', 'at once'],
        respuesta: 'whereupon'
    },
    {
        pregunta: 'If I’d studied harder at school, __________.',
        respuestas: ['I might have a better job now.', 'I could have had a better job now.', 'I would have a better job now'],
        respuesta: 'I might have a better job now.'
    },
    {
        pregunta: 'The salesman is asking a price that is _________ impossible.',
        respuestas: ['very','too','quite'],
        respuesta: 'quite'
    },
    {
        pregunta: 'Sid was _________ after his fall while skiing.',
        respuestas: ['not the bad', 'none the worst', 'none the worse'],
        respuesta: 'none the worse'
    },
    {
        pregunta: 'Tom crashed his father’s car, and now he’s trying his best to _________.',
        respuestas: ['make his mistake good', 'make his good mistake', 'make good his mistake'],
        respuesta: 'make his mistake good'
    },
]

export default Cursos
export { Cursos, Preguntas }
import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Tilt from 'react-parallax-tilt';
import '../../Styles/Public/landing.scss';
import 'animate.css/animate.min.css';
import Testimonios from './Testimonios';


const { Text, Title } = Typography


export default function Empresas(props) {



    return (
        <>
            <Layout className="mt-2">

                <Row justify={"center"} >
                    <Content className="mt-2 bg-landing center" style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                        <div className="max-1400">
                            <Row justify={"center"} className="mb-2">
                                <Text className="landing-title">
                                    Empresas
                                </Text>
                            </Row>
                            <Row justify={"center"}>
                                <Text className="landing-message">
                                    ¿Servicio a domicilio? Obvi. :D<br /><br />

                                    ¿Deseas que tus colaboradores aprovechen todos los beneficios que tiene esta hermosa herramienta? Te ayudamos a que su relación con el inglés sea la perfecta para que el negocio se vaya hasta arriba.

                                </Text>
                            </Row>
                        </div>

                    </Content>
                    <Content className="ml-2 mt-2 mr-2 mb-2 max-1400">

                        {/* <Row justify={"center"} className="mb-2">
                            <Text className="landing-title-2">
                                **What’s up!! Somos Inglés a la Mexicana**
                            </Text>
                        </Row> */}
                        <Row align={"middle"}>
                            <Col xs={24} md={12}>
                                <Row justify={"center"} className="">
                                    <Tilt tiltReverse="true" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                        <img src="https://images.pexels.com/photos/3182781/pexels-photo-3182781.jpeg" className="width-100" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} alt="group" />
                                    </Tilt>

                                </Row>

                            </Col>
                            <Col xs={24} md={12} className="height-100">

                                <Row>
                                    <Text className="landing-message-2 mt-2 pd-3">
                                        <ul className="puntos-clave">
                                            <li>
                                                Esquema personalizado.

                                            </li>
                                            <li>
                                                Todo el material incluído.
                                            </li>
                                            <li>
                                                Dinámicas referentes a su ramo.
                                            </li>
                                            <li>
                                                Seguimiento  personalizado para cada miembro del equipo.
                                            </li>
                                            <li>
                                                Seguimiento  personalizado

                                            </li>
                                        </ul>
                                    </Text>
                                </Row>
                                <Row justify={"center"}>

                                </Row>
                            </Col>
                        </Row>




                    </Content>
                </Row>

                <Row className="divisor-img pt-1 pb-1" >
                    <Col xs={24}>
                        {/* <Row justify={"center"} className="cursos-divisor-title">
                            25
                        </Row> */}
                        <Row justify={"center"} className="cursos-divisor-title">
                            Tu negocio en la cima
                        </Row>
                    </Col>
                    {/* <Col xs={24} sm={12} md={8}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            25
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            Cursos
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            25
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            Cursos
                        </Row>
                    </Col> */}
                </Row>

                <Content justify={"center"} className="ml-2 mt-3 mr-2 mb-2" >
                    

                        <Row justify={"center"}>
                            <Text className="landing-title-3">
                                ¿De qué va este curso?
                            </Text>
                        </Row>

                        <Row justify={"center"} className="mt-1">
                            <Text className="landing-content">
                                Nos reunimos para que nos expliques las necesidades que tienes con tu equipo, nosotros nos ponemos a trabajar y en breve regresamos con un plan de estudios hecho a la medida de tus necesidades. Con todas las particularidades que quieras agregar y enfocados en cumplir tu objetivo.
                            </Text>
                        </Row>

                        <Row justify={"center"} className="mt-2">
                            <Text className="landing-title-3">
                                ¿Quién me enseña?
                            </Text>
                        </Row>

                        <Row justify={"center"} className="mt-1">
                            <Text className="landing-content">
                                Un verdadero especialista en la enseñanza de inglés, egresado de la Licenciatura en Enseñanza de Inglés (UNAM).

                            </Text>
                        </Row>



                        <Row justify={"center"} className="mt-2">
                            <a href="http://m.me/IngsalaMex?text=Quiero%20un%curso%20para%20mi%20empresa" target="_blank" rel="noreferrer">
                                <Tag className="info-tag">
                                    Quiero reunirme con ustedes
                                </Tag>
                            </a>

                        </Row>

                        <Row>
                            <Testimonios />
                        </Row>
                    
                </Content>

            </Layout>
        </>

    )

}



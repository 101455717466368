import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import '../../Styles/Public/landing.scss';
import { ExamenIcon } from "../Widgets/Icons";
import OwlCarousel from 'react-owl-carousel';
import 'animate.css/animate.min.css';
import WOW from 'wowjs';
import moment from 'moment';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useScroll, useTransform, motion } from "framer-motion";

import TuiCalendar from 'tui-calendar'; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";

import { animated, useSpring } from "react-spring";
import Tilt from 'react-parallax-tilt';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Link, useNavigate } from "react-router-dom";
import Cursos from "./Examen/examanes";
import Testimonios from "./Testimonios";
// import Paragraph from "antd/lib/skeleton/Paragraph";


const { Text, Paragraph } = Typography;




class Calendar extends React.Component {

    calendarRef = React.createRef()

    componentDidMount() {
        this.calendarRef.current = new TuiCalendar(this.calendarRef.current, {
            useDetailPopup: false,
            taskView: false,
            calendars: Cursos.map((e, i) => ({
                id: String(i),
                name: e.title,
                bgColor: e.color,
                color: this.getTextColor(e.color)
            })),
            template: {
                popupDetailDate: (isAllDay, start, end) => {

                    const formatHour12 = (hour24) => {
                        const hour12 = hour24 % 12 || 12;
                        const ampm = hour24 < 12 ? 'AM' : 'PM';
                        return `${hour12} ${ampm}`;
                    };

                    return formatHour12(start.getHours()) + ' - ' + formatHour12(end.getHours())
                }
            },
            disableClick: true,
            disableDblClick: true,
            week: {
                daynames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
                startDayOfWeek: 1,
                hourStart: 9,
                hourEnd: 22,
            },
        })

        this.calendarRef.current.render()

        // calendar;
        this.calendarRef.current.setOptions({
            useDetailPopup: true,
        })
        this.calendarRef.current.createSchedules(this.formatSchedules(Cursos))
    }


    formatSchedules = (courses) => {
        const baseDate = moment().startOf('isoWeek').isoWeekday(1)
        const list = courses.flatMap((course, indexCourse) => {
            return course.horarios.flatMap((horario, horarioIndex) => {
                return horario.weekdays.flatMap((day, index) => {

                    const dayOffset = day - 1; // Obtener el índice del día de la semana (0 = lunes, 1 = martes, ...)
                    const startDate = baseDate.clone().add(dayOffset, 'days').set({
                        hour: moment(horario.start, 'HH:mm:ss').hour(),
                        minute: moment(horario.start, 'HH:mm:ss').minute(),
                        second: moment(horario.start, 'HH:mm:ss').second()
                    });
                    const endDate = baseDate.clone().add(dayOffset, 'days').set({
                        hour: moment(horario.end, 'HH:mm:ss').hour(),
                        minute: moment(horario.end, 'HH:mm:ss').minute(),
                        second: moment(horario.end, 'HH:mm:ss').second()
                    });
                    return {
                        id: String(indexCourse) + String(horarioIndex) + String(index),
                        calendarId: String(indexCourse),
                        title: `${course.title} (${horario.title})`,
                        category: course.title,
                        start: startDate.toDate(),
                        end: endDate.toDate(),
                        category: 'time',
                    }
                })
            })

        })

        console.log('e', list);


        return list
    }

    getTextColor = (bgColorHex) => parseInt(bgColorHex.replace('#', ''), 16) > 0xffffff / 2 ? '#000000' : '#ffffff';



    render() {

        return <div className="week-calendar" ref={this.calendarRef} />

    }
}


export default function Landing(props) {

    const refLetras = useRef(null)

    let [cursos, setCursos] = useState(Cursos)

    let [hoverLandImg, setHoverLandImg] = useState(false)

    let [windowWidth, setWindowWidth] = useState(window.innerWidth)

    let navigate = useNavigate()

    useEffect(() => {

        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth)
        }

        new WOW.WOW({
            live: false
        }).init();

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }


        // props.setHeader({ color: null, logo: null })

    }, [])

    const propsImg = useSpring({
        to: { translateX: hoverLandImg ? 0 : 0, rotateY: hoverLandImg ? 0 : -25 },
    })



    const { scrollYProgress } = useScroll({
        ref: refLetras,
        offset: ["end end", "start start"]
    })

    const x = useTransform(scrollYProgress, [1, 0], [windowWidth * -2, windowWidth * 13])


    return (
        <>
            <Layout>

                <Content className="full-page bg-landing">

                    <Row align={"middle"} className="full-height bg-elipse-landing" gutter={[0, 50]}>
                        <Col xs={24} lg={12} >

                            <Row justify={"center"}>
                                <Text className="landing-title">
                                    Inglés a la Mexicana
                                </Text>
                            </Row>

                            <Row justify={"center"}>
                                <Text className="landing-message mt-2">
                                    Especialistas en la enseñanza de inglés para mexicanos
                                </Text>
                            </Row>

                            <Row justify={"center"}>
                                <Button className="boton-claro mt-2" onClick={() => navigate('/#schedules')}>
                                    Ver los cursos
                                </Button>
                            </Row>

                        </Col>
                        <Col xs={24} lg={12} className="pd-2 image-container">
                            <animated.div style={{
                                perspective: 2000, width: "100%"
                            }}
                                onMouseEnter={() => setHoverLandImg(true)}
                                onMouseLeave={() => setHoverLandImg(false)}>
                                <animated.div
                                    style={{
                                        ...propsImg, boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.25)',
                                        borderRadius: "10px", width: "100%"
                                    }}
                                >
                                    <img src="/img/austin-distel-rxpThOwuVgE-unsplash.jpg" className="width-100" alt="group" style={{ borderRadius: "10px" }} />
                                </animated.div>
                            </animated.div>

                        </Col>
                    </Row>
                </Content>
                <div id="about-us"></div>
                <Row justify={"center"}>
                    <Content className="ml-2 mt-3 mr-2 max-1400">
                        <Row justify={"center"} className="mb-2">
                            <Text className="landing-title-2">
                                **What’s up!! Somos Inglés a la Mexicana**
                            </Text>
                        </Row>
                        <Row align={"middle"}>
                            <Col xs={24} md={12}>
                                <Row justify={"center"} className="mr-2 bg-blue-elipsis">
                                    <Tilt tiltReverse="true" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                        <img src="/img/avel-chuklanov-9OWS4lT5iqQ-unsplash.jpg" className="width-100" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} alt="group" />
                                    </Tilt>

                                </Row>

                            </Col>
                            <Col xs={24} md={12} className="height-100">

                                <Row>
                                    <Text className="landing-message-2 mt-2 pd-3">
                                        <ul className="puntos-clave">
                                            <li>
                                                El mejor curso para acreditar el inglés en las universidades públicas según 3000 egresados ya titulados.
                                            </li>
                                            <li>
                                                Proyecto creado por egresados de UNAM e IPN.
                                            </li>
                                            <li>
                                                Vibramos alto los 7 días de la semana :P
                                            </li>
                                            <li>
                                                Creadores del primer método diseñado exclusivamente para mexicanos y por mexicanos. Desde nivel básico.
                                            </li>
                                            <li>
                                                Amantes de los tacos de puesto esquinero.
                                            </li>
                                            <li>
                                                Los únicos con un CURSO Conversacional que te hace perder el miedo de hablar y hacerlo de la manera correcta.
                                            </li>
                                            <li>
                                                Acá las clases sólo son llevadas por licenciados en enseñanza de inglés. El resultado está garantizado.
                                            </li>
                                        </ul>
                                    </Text>
                                </Row>
                                <Row justify={"center"}>
                                    <Link to='/cursos/libera-tu-ingles'>
                                        <Tag className="info-tag">
                                            Ver más
                                        </Tag>
                                    </Link>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Row>

                <Content>
                    <Row justify={"center"} className="text-slide">
                        <motion.div ref={refLetras} style={{ translateX: x }}>
                            <Text className="divider-text"> INGLÉS CHINGÓN PARA GENTE CHINGONA </Text>
                        </motion.div>




                    </Row>
                </Content>
                <Content id="courses">
                    <Row align={"middle"}>
                        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 1 }} style={{ zIndex: "1" }}>

                            <img src="/img/javier-trueba-iQPr1XkF5F0-unsplash.jpg" className="width-100 min-height-600" alt="group" />

                        </Col>
                        <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 2 }} className="seccion-info wow animate__animated animate__fadeInLeft">
                            <Row justify={"center"} align={"middle"} className="p-2" gutter={[0, 0]}>
                                <div>
                                    <Col span={24} className="mt-1">
                                        <Row align={"center"} className="width-100 mb-1">
                                            <Tag className="info-tag">
                                                Que el inglés no detenga tus metas.
                                            </Tag>
                                        </Row>
                                    </Col>
                                    <Col span={24} className="mt-1">
                                        <Link to="/cursos/libera-tu-ingles">
                                            <Text className="landing-title-3 center">
                                                Libera el inglés en tu universidad y titulate.
                                            </Text>
                                        </Link>

                                    </Col>
                                    <Col span={24} className="mt-1">
                                        <Text className="landing-content center">
                                            Adquiere y domina las estrategias que te hacen asegurar tu resultado en los exámenes de dominio del idioma. Más de 3000 resultados positivos en UNAM, UAM e IPN
                                        </Text>
                                    </Col>
                                    <Col span={24} className="mt-2">
                                        {/* <Link to="/cursos/libera-tu-ingles"> */}
                                        <Row justify={"center"} gutter={[0, 20]}>

                                            <Col xs={12} md={8}>
                                                <Link to='/cursos/examen-global-unam'>
                                                    <Row justify={"center"}>
                                                        <Tag className="info-tag bounce">
                                                            UNAM
                                                        </Tag>
                                                    </Row>
                                                </Link>
                                            </Col>

                                            <Col xs={12} md={8}>
                                                <Link to='/cursos/comprension-lectora'>
                                                    <Row justify={"center"}>
                                                        <Tag className="info-tag bounce">
                                                            UAM
                                                        </Tag>
                                                    </Row>
                                                </Link>
                                            </Col>

                                            <Col xs={12} md={8}>
                                                <Link to='/cursos/acreditacion'>
                                                    <Row justify={"center"}>
                                                        <Tag className="info-tag bounce">
                                                            IPN
                                                        </Tag>
                                                    </Row>
                                                </Link>
                                            </Col>

                                        </Row>
                                        {/* </Link> */}



                                    </Col>
                                </div>

                            </Row>

                        </Col>
                        <Col xs={{ span: 24, order: 4 }} lg={{ span: 12, order: 3 }} className="seccion-info wow animate__animated animate__fadeInRight">
                            <Row justify={"center"} align={"middle"}>
                                <Col span={24} className="mt-1">
                                    <Row align={"center"} className="width-100 mb-1">
                                        <Tag className="info-tag" style={{ backgroundColor: '#2BD073' }}>
                                            El curso definitivo para aprender inglés.
                                        </Tag>
                                    </Row>
                                </Col>


                                <Col span={24} className="mt-1">
                                    <Link to="/cursos/basico">
                                        <Text className="landing-title-3 center">
                                            Enfocado en Writing y Speaking.
                                        </Text>
                                    </Link>

                                </Col>
                                <Col span={24} className="mt-1">
                                    <Text className="landing-content center">
                                        Los idiomas no sólo se aprenden, se hablan. Por eso nos enfocamos en que formes bases sólidas del idioma y produzcas desde la primera clase. “No hay forma de aprender inglés sin usarlo”
                                    </Text>
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Space align="center" className="width-100 center">
                                        <Link to="/cursos/basico">
                                            <Tag className="info-tag bounce">
                                                ¡Jalo!
                                            </Tag>
                                        </Link>

                                    </Space>

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={{ span: 24, order: 3 }} lg={{ span: 12, order: 4 }} style={{ zIndex: "1" }}>
                            <img src="/img/redd-f-PTRzqc_h1r4-unsplash.jpg" className="width-100 min-height-600" alt="imagen" />
                        </Col>
                        <Col xs={{ span: 24, order: 5 }} lg={{ span: 12, order: 5 }} style={{ zIndex: "1" }}>
                            <img src="/img/jason-goodman-vbxyFxlgpjM-unsplash.jpg" className="width-100 min-height-600" alt="imagen" />
                        </Col>
                        <Col xs={{ span: 24, order: 6 }} lg={{ span: 12, order: 6 }} className="seccion-info wow animate__animated animate__fadeInLeft">
                            <Row justify={"center"} align={"middle"}>
                                <Col span={24} className="mt-1">
                                    <Row align={"center"} className="width-100 mb-1">
                                        <Tag className="info-tag" style={{ backgroundColor: '#A1D92C' }}>
                                            Vuélvete el rey de la conversación.
                                        </Tag>
                                    </Row>
                                </Col>

                                <Col span={24} className="mt-1">
                                    <Link to="/cursos/speak-king">
                                        <Text className="landing-title-3 center">
                                            Adiós al miedo de hablar.
                                        </Text>
                                    </Link>

                                </Col>
                                <Col span={24} className="mt-1">
                                    <Text className="landing-content center">
                                        Aquí hablas y ganas la confianza para hacerlo en cualquier escenario. El ambiente se pone sabroso y no hay lugar para la pena, burla o miedo. ¡Gózalo!
                                    </Text>
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Space align="center" className="width-100 center">

                                        <Link to="/cursos/speak-king">
                                            <Tag className="info-tag bounce">
                                                ¡Entro!
                                            </Tag>
                                        </Link>



                                    </Space>

                                </Col>
                            </Row>

                        </Col>

                        <Col xs={{ span: 24, order: 8 }} lg={{ span: 12, order: 7 }} className="seccion-info wow animate__animated animate__fadeInRight">
                            <Row justify={"center"} align={"middle"}>
                                <Col span={24} className="mt-1">
                                    <Row align={"center"} className="width-100 mb-1">
                                        <Tag className="info-tag" style={{ backgroundColor: "#56B6DD" }}>
                                            Como un buen traje, a tu medida.
                                        </Tag>
                                    </Row>
                                </Col>

                                <Col span={24} className="mt-1">
                                    <Link to="/cursos/a-tu-medida">
                                        <Text className="landing-title-3 center">
                                            A tu ritmo, a tu gusto, 1 a 1 y perfecto
                                        </Text>
                                    </Link>

                                </Col>
                                <Col span={24} className="mt-1">
                                    <Text className="landing-content center">
                                        Si quieres una experiencia más puntual y personalizada, este es el bueno para alcanzar tus objetivos.
                                    </Text>
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Space align="center" className="width-100 center">
                                        <Link to="/cursos/a-tu-medida">
                                            <Tag className="info-tag bounce">
                                                Lo que busco
                                            </Tag>
                                        </Link>


                                    </Space>

                                </Col>
                            </Row>

                        </Col>
                        <Col xs={{ span: 24, order: 7 }} lg={{ span: 12, order: 8 }} style={{ zIndex: "1" }}>
                            <img src="/img/annie-spratt-QckxruozjRg-unsplash.jpg" className="width-100 min-height-600" alt="imagen" />
                        </Col>
                        <Col xs={{ span: 24, order: 9 }} lg={{ span: 12, order: 9 }} style={{ zIndex: "1" }}>
                            <img src="/img/jason-goodman-bzqU01v-G54-unsplash.jpg" className="width-100 min-height-600" alt="imagen" />
                        </Col>
                        <Col xs={{ span: 24, order: 10 }} lg={{ span: 12, order: 10 }} className="seccion-info wow animate__animated animate__fadeInLeft">
                            <Row justify={"center"} align={"middle"}>
                                <Col span={24} className="mt-1">
                                    <Row align={"center"} className="width-100 mb-1">
                                        <Tag className="info-tag">
                                            Tu equipo de trabajo te amará por siempre.
                                        </Tag>
                                    </Row>
                                </Col>

                                <Col span={24} className="mt-1">
                                    <Link to="/cursos/empresas">
                                        <Text className="landing-title-3 center">
                                            Lleva toda la experiencia IM hasta tus oficinas
                                        </Text>
                                    </Link>

                                </Col>
                                <Col span={24} className="mt-1">
                                    <Text className="landing-content center">
                                        ¿Tus colaboradores necesitan el idioma para mejorar el rendimiento de la empresa? Nos encargamos de ello. Tú dinos que estás buscando y nosotros lo hacemos realidad.</Text>
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Space align="center" className="width-100 center">

                                        <Link to="/cursos/empresas">
                                            <Tag className="info-tag bounce">
                                                Platiquemos
                                            </Tag>
                                        </Link>


                                    </Space>

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Content>
                <Content className="mt-2">
                    <Row justify={"center"} align={"middle"} style={{ padding: "4em" }}>
                        <Text className="texto-grande center">
                            Síguenos en nuestras redes
                        </Text>
                    </Row>
                    <Row className="mt-2 mb-2" align={"middle"}>

                        <Col xs={24} md={12}>
                            <a href="https://www.tiktok.com/@inglesalamexicana/video/7194579574498315525?_r=1&_t=8bo3YyvKeaz" target="_blank" rel="noreferrer">
                                <video width="100%" autoPlay={true} muted playsInline loop>
                                    <source
                                        src="/vid/out.mp4"
                                        type='video/mp4; codecs="hvc1"' />
                                    <source
                                        src="/vid/out.webm"
                                        type="video/webm" />
                                </video>
                            </a>


                        </Col>

                        <Col xs={24} md={12} className="p-2 height-100">
                            <Row className="mt-2 mb-2">
                                <Text className="landing-content center">
                                    Por acá encontrarás buenos tips, recursos, herramientas y hasta unos buenos bailes; todo para que aprendas de forma divertida y mejores tu nivel súper rápido. ¡Síguenos!👇
                                </Text>
                            </Row>
                            <Row justify={"space-around"} className="mt-2">
                                <Col xs={12} md={8} lg={8}>
                                    <Row justify={"center"} align={"middle"}>
                                        <a href="https://www.facebook.com/IngsalaMex" target="_blank" rel="noreferrer">
                                            <img src="/img/fb.png" alt="fb" />
                                        </a>

                                    </Row>
                                </Col>
                                <Col xs={12} md={8} lg={8}>
                                    <Row justify={"center"} align={"middle"}>
                                        <a href="https://www.instagram.com/ingsalamex/" target="_blank" rel="noreferrer">
                                            <img src="/img/ig.png" alt="ig" />
                                        </a>
                                    </Row>
                                </Col>
                                <Col xs={12} md={8} lg={8}>
                                    <Row justify={"center"} align={"middle"}>
                                        <a href="https://www.tiktok.com/@inglesalamexicana?source=h5_m" target="_blank" rel="noreferrer">
                                            <img src="/img/tiktok.png" alt="tiktok" />
                                        </a>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </Content>

                <Content className='divisor-img' style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                    <Row justify={"center"}>
                        <Text className="landing-content text-white">
                            Comunidad Apoyada
                        </Text>
                    </Row>
                    <Row justify={"space-around"} align={"middle"} gutter={[0, 40]} className="mt-2">



                        <Col xs={24} md={12} lg={8}>
                            <Row justify={"center"}>
                                <img src="/img/ipn.png" alt="IPN" className="hover-bounce" />
                            </Row>
                        </Col>

                        <Col xs={24} md={12} lg={8}>
                            <Row justify={"center"}>
                                <img src="/img/uam.png" alt="UAM" className="hover-bounce" />
                            </Row>
                        </Col>

                        <Col xs={24} md={12} lg={8}>
                            <Row justify={"center"}>
                                <img src="/img/unam.png" alt="UNAM" className="hover-bounce" />
                            </Row>
                        </Col>

                    </Row>
                </Content>
                <Content className="mt-2 mb-2" id="schedules" >
                    <Row justify={"center"} align={"middle"} style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                        <Text className="texto-grande mb-2">
                            ¡Revisa los horarios de nuestros cursos!
                        </Text>
                    </Row>
                    {/* <Row justify={"center"} align={"middle"}  >

                        <Calendar />
                    </Row> */}
                    <div className="events">

                        <Row justify={"center"} className="cards-articulos mt-2" gutter={[12, 30]}>
                            {cursos.map((curso, index) => {

                                let baseUrl = "https://api.whatsapp.com/send?phone=525585369612"
                                let url = new URL(baseUrl)
                                url.searchParams.append("text", 'Me interesa el curso "' + curso.title + '"')
                                console.log(url.toString())
                                return (
                                    <Col xs={24} md={12} lg={12} xl={8} xxl={6} >

                                        <a href={url.href} target="_blank" rel="noreferrer">
                                            <Card
                                                hoverable
                                                style={{ backgroundColor: curso.color, height: "100%" }}
                                                cover={<img alt="example" src={curso.image}
                                                    style={{ aspectRatio: "16/11", objectFit: "cover", objectPosition: "center" }}
                                                />}>
                                                <Row justify={"center"}>
                                                    <Text className="card-title">
                                                        {curso.title}
                                                    </Text>
                                                </Row>
                                                <Row justify={"center"} className="mt-1" style={{
                                                    color: "white",
                                                    fontFamily: "Livvic",
                                                    textAlign: "justify"
                                                }}>
                                                    {curso.description}
                                                    {/* <ReactMarkdown rehypePlugins={[rehypeRaw]} className="card-content"> */}
                                                    {/* </ReactMarkdown> */}
                                                </Row>
                                            </Card>
                                        </a>



                                    </Col>
                                )
                            })}
                            {/* <Button className="boton-claro2">
                            Ver cursos
                        </Button> */}
                        </Row>
                    </div>

                </Content>
                <Content className="bg-landing" style={{ paddingTop: "6em", paddingBottom: "6em" }}>
                    <Row justify={"center"}>
                        <ExamenIcon />
                    </Row>
                    <Row justify={"center"}>
                        <Text className="divider-title text-white mt-1">
                            Prueba tu nivel de inglés
                        </Text>
                    </Row>
                    <Row justify={"center"} >
                        <Paragraph className="divider-content" style={{ lineHeight: 2, width: "100%" }}>
                            ¡Conoce tu nivel y permítenos encontrar el curso perfecto para ti!
                        </Paragraph>
                    </Row>
                    <Row justify={"center"} >
                        <Link to="/examen-diagnostico">
                            <Button className="boton-claro">
                                Examen de Diagnóstico
                            </Button>
                        </Link>
                    </Row>
                </Content>
                <Content className="mt-2 mb-2">
                    <Row justify={"center"} className="mt-2">
                        <Text className="texto-grande2">
                            ¿Qué dicen de nosotros?
                        </Text>
                    </Row>
                    <Row className="mt-1" justify={"center"}>
                        <Text className="about-content">
                            Dejamos que la experiencia de nuestros alumnos y colaboradores hable por nosotros
                        </Text>
                    </Row>
                    <Row className="width-100 mt-2" justify={"center"}>
                        {/* <Card className="card-opinion">
                            <Row>
                                <Col xs={24} md={7} className="center width-100">
                                    <Row justify={"center"}>
                                        <Avatar size={250} src="/img/perfil.png" />
                                    </Row>
                                    <Row justify={"center"} className="mt-2">
                                        <Text className="nombre">IPN UPICSA</Text>
                                    </Row>
                                </Col>
                                <Col xs={24} md={17}>
                                    <Row>
                                        <Text className="opinion">
                                            ¡Simplemente excelente servicio! Acredité mi inglés súper rápido y con un muy buen método, práctico y divertido. No es un negocio más con el idioma, son personas que aman lo que hacen, enseñar inglés de una manera que si funciona.
                                            Súper recomendado, 5 estrellas y todo mi agradecimiento por ayudarme a acreditar el requisito de inglés.
                                        </Text>
                                    </Row>
                                    <Row className="mt-2">
                                        <Tag className="tag-nombre">
                                            - Gigudem Pérez
                                        </Tag>
                                    </Row>
                                </Col>
                            </Row>
                        </Card> */}
                        <Testimonios />
                    </Row>

                    <Row className="mt-2" justify={"center"}>
                        {/* <a href="/#" className="ver-mas">
                            Ver más casos de éxito
                        </a> */}
                    </Row>
                </Content>

                <Row align={"middle"} justify={"end"} gutter={[20, 0]} className="sticky-redes mr-1 mb-1">
                    <Col>
                        <a href="https://www.facebook.com/IngsalaMex" target="_blank" rel="noreferrer">
                            <Avatar size={65} src="/img/messenger_logo.svg" style={{ backgroundColor: "#FFFFFF", border: "8px solid white", boxShadow: "4px 4px 15px rgba(48, 48, 48, 0.10)" }} />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://api.whatsapp.com/send?phone=525585369612&text=Estoy+interesado+en+un+curso" target="_blank" rel="noreferrer">
                            <Avatar size={65} src="/img/whatsapp_logo.svg" className="whatss" style={{ backgroundColor: "#FFFFFF", border: "12px solid white", boxShadow: "4px 4px 15px rgba(48, 48, 48, 0.10)" }} />
                        </a>
                    </Col>
                </Row>

            </Layout>
        </>

    )

}



import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Tilt from 'react-parallax-tilt';
import '../../Styles/Public/landing.scss';
import 'animate.css/animate.min.css';



import Testimonios from './Testimonios';



const { Text, Title } = Typography


export default function AcreditacionUNAM(props) {



    return (
        <>
            <Layout className="mt-2">

                <Row justify={"center"} >
                    <Content className="mt-2 bg-landing center" style={{ paddingTop: "4em", paddingBottom: "3em" }}>
                        <div className="max-1400">
                            <Row justify={"center"} className="mb-2">
                                <Text className="landing-title page-title">
                                    Acreditación UNAM
                                    <br />
                                    <small>(Dominio Global).</small>
                                </Text>
                            </Row>
                            <Row justify={"center"}>
                                <Text className="landing-message page-subtitle center" style={{}}>
                                    Ya es hora de que tu título sea presumido por toda tu familia, licenciad@.<br />
                                    Lleva tu comprensión de lectura a otro nivel.

                                </Text>
                            </Row>
                        </div>
                    </Content>
                    <Content className="ml-2 mt-2 mr-2 mb-2 max-1400" style={{ margin: "auto" }}>
                        <Row align={"middle"}>
                            <Col xs={24} md={12}>
                                <Row justify={"center"}>
                                    <Tilt tiltReverse="true" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                        <img src="/img/cursos/plan_global.jpg" className="width-100" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} alt="group" />
                                    </Tilt>

                                </Row>

                            </Col>
                            <Col xs={24} md={12} className="height-100">

                                <Row>
                                    <Text className="landing-message-2 mt-2 pd-3">
                                        <ul className="puntos-clave">
                                            <li>
                                                20 horas de curso
                                            </li>
                                            <li>
                                                Todo el material incluído
                                            </li>
                                            <li>
                                                2 exámenes simulacro
                                            </li>
                                            <li>
                                                Seguimiento  personalizado
                                            </li>
                                            <li>
                                                Asesoría en registro de examen.
                                            </li>
                                            <li>
                                                Grupos reducidos.
                                            </li>
                                        </ul>
                                    </Text>
                                </Row>
                                <Row justify={"center"}>

                                </Row>
                            </Col>
                        </Row>




                    </Content>
                </Row>

                <Row className="divisor-img pt-1 pb-1" justify={"center"}>
                    <Col xs={24}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            ¡Más de 1000 Pumas ya titulados!
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            Acreditación UNAM (Plan Global y Comprensión)
                        </Row>
                    </Col>
                </Row>

                <Content justify={"center"} className="ml-2 mt-3 mr-2 mb-2 max-1400">


                    <Row justify={"center"}>
                        <Text className="landing-title-3">
                            ¿De qué va este curso?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Es el complemento perfecto de tus estudios en la carrera. Sabemos que “liberar el inglés” es lo único que te falta y te vamos a ayudar en 1 mes a lograrlo. Domina las estrategías que son necesarias para entender cualquier texto en inglés y los tips  para que el examen sea sencillito.

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Quién me enseña?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Un verdadero especialista en la enseñanza de inglés, egresado de la Licenciatura en Enseñanza de Inglés (UNAM). Que está listo para que todo el contenido del curso te quede claro.

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Puede entrar cualquiera?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Sí y no. Cualquier persona tiene acceso al curso y a los exámenes, pero para aprovechar el curso de la mejor manera, es necesario contar con un conocimiento previo sólido, de otra manera, el resultado final no será el mejor. <br />
                            Para saber si el curso te beneficia, puedes hacer la prueba que está en la pestaña del menú principal.

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-2">
                            HORARIOS DISPONIBLES
                        </Text>
                    </Row>
                    <Row justify={"center"} className="mt-2 mb-2">
                        <p style={{ textAlign: "center" }}>
                            <strong>Lunes a Jueves</strong> <br />
                            20:00 a 22:00 horas
                        </p>
                    </Row>

                    <Row justify={"center"}>
                        <a href="http://m.me/IngsalaMex?text=Quiero%20reservar%20mi%20lugar%20para%20el%20examen%20global" target="_blank" rel="noreferrer">
                            <Tag className="info-tag">
                                Reservar mi lugar
                            </Tag>
                        </a>

                    </Row>

                    <Row>
                        <Testimonios />
                    </Row>


                </Content>




            </Layout>
        </>

    )

}



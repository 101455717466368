import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Tilt from 'react-parallax-tilt';
import '../../Styles/Public/landing.scss';
import 'animate.css/animate.min.css';
import Testimonios from './Testimonios';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';


const { Text, Title } = Typography


export default function Medida(props) {



    return (
        <>
            <Layout className="mt-2">

                <Row justify={"center"} >
                    <Content className="mt-2 bg-landing center" style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                        <div className="max-1400">
                            <Row justify={"center"} className="mb-2">
                                <Text className="landing-title">
                                    Curso a tu medida
                                </Text>
                            </Row>
                            <Row justify={"center"}>
                                <Text className="landing-message">
                                    ¿Andas en busca de una experiencia muuuy personalizada?<br />
                                    Acá, como un buen traje, todo a la medida.<br /><br />
                                </Text>
                            </Row>
                        </div>

                    </Content>
                    <Content className="ml-2 mt-2 mr-2 mb-2 max-1400">

                        {/* <Row justify={"center"} className="mb-2">
                            <Text className="landing-title-2">
                                **What’s up!! Somos Inglés a la Mexicana**
                            </Text>
                        </Row> */}
                        <Row align={"middle"}>
                            <Col xs={24} md={12}>
                                <Row justify={"center"} >
                                    <Tilt tiltReverse="true" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                        <img src="/img/pexels.jpg" className="width-100" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} alt="group" />
                                    </Tilt>

                                </Row>

                            </Col>
                            <Col xs={24} md={12} className="height-100">

                                <Row>
                                    <Text className="landing-message-2 mt-2 pd-3">
                                        <p className="puntos-clave">
                                            Si tienes un objetivo específico con el idioma y quieres toda la atención para ti solo, este curso es tu aliado perfecto. <br />
                                            Todo se gestiona para que cumplas tus planes en tiempo record y cara a cara con el profesor, a tus tiempos y con tus condiciones.
                                        </p>
                                        <ul className="puntos-clave">
                                            <li>
                                                Mínimo 3 horas a la semana.

                                            </li>
                                            <li>
                                                Tú escoges tus horarios.
                                            </li>
                                            <li>
                                                Todo el material incluído.
                                            </li>
                                            <li>
                                                Enfoque en tus necesidades


                                            </li>
                                            <li>
                                                Seguimiento  personalizado

                                            </li>
                                        </ul>
                                    </Text>
                                </Row>
                                <Row justify={"center"}>

                                </Row>
                            </Col>
                        </Row>
                    </Content>
                </Row>
                <Row className="divisor-img pt-1 pb-1" >
                    <Col xs={24}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            Tu curso, a tu medida, tu éxito.
                            {/* 25 */}
                        </Row>
                        {/* <Row justify={"center"} className="cursos-divisor-subtitle">
                        </Row> */}
                    </Col>
                </Row>


                <Content justify={"center"} className="ml-2 mt-3 mr-2 mb-2" >


                    <Row justify={"center"}>
                        <Text className="landing-title-3">
                            ¿De qué va este curso?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Tal cual, de lo que tú necesites. Un asesor se comunica contigo y le platicas tus planes. Nosotros encontramos al profesor perfecto para tu objetivo y echamos a andar todo para que sea una experiencia increíble para ti.
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Quién me enseña?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Un verdadero especialista en la enseñanza de inglés, egresado de la Licenciatura en Enseñanza de Inglés (UNAM).

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Puede entrar cualquiera?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Sí,este curso al ser completamente personalizado, se hace a tu gusto, no importa sí es para aprender, mejorar o por placer.
                        </Text>
                    </Row>


                    <Row justify={"center"} className="mt-2">
                        <a href="http://m.me/IngsalaMex?text=Quiero%20mi%curso%20personalizado" target="_blank" rel="noreferrer">
                            <Tag className="info-tag">
                                Reservar mi lugar
                            </Tag>
                        </a>
                    </Row>
                    <Row>
                        <Testimonios />
                    </Row>
                </Content>
            </Layout>
        </>

    )

}



import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import LiberaIngles from "../Components/Public/LiberaIngles";
import Acreditacion from "../Components/Public/Acreditacion";
import ComprensionLectora from "../Components/Public/ComprensionLectora";
import AcreditacionUNAM from "../Components/Public/AcreditacionUNAM";
import CursoBasico from "../Components/Public/Basico";
import SpeaKing from "../Components/Public/SpeaKing";
import Medida from "../Components/Public/Medida";
import Empresas from "../Components/Public/Empresas";



export default function CoursesRoutes() {

    return (

        <Routes>
            <Route path="/libera-tu-ingles" element={<LiberaIngles />} />
            <Route path="/acreditacion" element={<Acreditacion />} />
            <Route path="/comprension-lectora" element={<ComprensionLectora />} />
            <Route path="/examen-global-unam" element={<AcreditacionUNAM />} />
            <Route path="/basico" element={<CursoBasico />} />
            <Route path="/speak-king" element={<SpeaKing />} />
            <Route path="/a-tu-medida" element={<Medida />} />
            <Route path="/empresas" element={<Empresas />} />
        </Routes>


    )

}
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation  } from "react-router-dom";

import Landing from "../Components/Public/Landing";
import { Layout } from "antd";
import HeaderPublic from "../Components/Public/Header";
import ScrollToHash from "../Components/Widgets/ScrollToHash";
import FooterPublic from "../Components/Public/Footer";
import InicioExamen from "../Components/Public/Examen/InicioExamen";
import Examen from "../Components/Public/Examen/Examen";
import CoursesRoutes from "./CoursesRoutes";

export default function PublicRoutes() {

    let [{color, logo}, setHeader] = useState({color: null, logo: null})


    // window.scrollTo(0, 0)

    const location = useLocation()

    useEffect(() => {
        // execute on location change
        // setCount(count + 1);
        window.scrollTo(0, 0)
        console.log('Location changed!', );
    }, [location.pathname])

    return (
        <Layout>
            <HeaderPublic color={color} logo={logo}/>
            <Routes>
                <Route path="/*" element={<Landing setHeader={setHeader}/>} />
                <Route path="/cursos/*" element={<CoursesRoutes />} />
                <Route path="/examen-diagnostico" element={<InicioExamen setHeader={setHeader}/>} />
                <Route path="/examen" element={<Examen setHeader={setHeader}/>} />
            </Routes>
            <FooterPublic/>
        </Layout>

    )

}
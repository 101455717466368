import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Component, useEffect, useState, useRef } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Tilt from 'react-parallax-tilt';
import '../../Styles/Public/landing.scss';
import 'animate.css/animate.min.css';
import OwlCarousel from 'react-owl-carousel';
import Testimonios from './Testimonios';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



const { Text, Title } = Typography


export default function SpeaKing(props) {



    return (
        <>
            <Layout className="mt-2">

                <Row justify={"center"} >
                    <Content className="mt-2 bg-landing center" style={{ paddingTop: "4em", paddingBottom: "4em" }}>
                        <div className="max-1400">
                            <Row justify={"center"} className="mb-2">
                                <Text className="landing-title">
                                    Speak-king
                                </Text>
                            </Row>
                            <Row justify={"center"}>
                                <Text className="landing-message">
                                    ¿Necesitas HABLAR inglés EN el trabajo para mejorar tu salario?<br />
                                    Vuélvete el rey de la conversación en 4 meses.<br /><br />
                                </Text>
                            </Row>
                        </div>

                    </Content>
                    <Content className="ml-2 mt-2 mr-2 mb-2 max-1400">

                        {/* <Row justify={"center"} className="mb-2">
                            <Text className="landing-title-2">
                                **What’s up!! Somos Inglés a la Mexicana**
                            </Text>
                        </Row> */}
                        <Row align={"middle"}>
                            <Col xs={24} md={12}>
                                <Row justify={"center"}>
                                    <Tilt tiltReverse="true" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                        <img src="/img/pexels.jpg" className="width-100" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} alt="group" />
                                    </Tilt>

                                </Row>

                            </Col>
                            <Col xs={24} md={12} className="height-100">

                                <Row>
                                    <Text className="landing-message-2 mt-2 pd-3">
                                        <p>
                                            Disfruta del único curso conversacional en México, diseñado para que tu producción y precisión al hablar sea perfecta. No más miedo o pena de hablar en inglés.<br /><br />
                                            En 4 módulos haremos que hables como en tu serie favorita.
                                        </p>

                                        <ul className="puntos-clave">
                                            <li>
                                                15 horas por módulo.
                                            </li>
                                            <li>
                                                100% conversacional.
                                            </li>
                                            <li>
                                                Grupo reducido.
                                            </li>
                                            <li>
                                                Vocabulario y slang

                                            </li>
                                            <li>
                                                Corrección de estructuración de tus ideas y pronunciación.
                                            </li>
                                        </ul>
                                    </Text>
                                </Row>
                                <Row justify={"center"}>

                                </Row>
                            </Col>
                        </Row>




                    </Content>
                </Row>

                <Row className="divisor-img pt-1 pb-1" >
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            10 
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            Personas por Grupo
                        </Row>
                    </Col>
                    {/* <Divider /> */}
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            Pronunciación
                        </Row>
                        <Row justify={"center"} className="cursos-divisor-subtitle">
                            y estructuración de ideas
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            100%
                        </Row>
                        <Row style={{ textAlign: "center" }} justify={"center"} className="cursos-divisor-subtitle">
                            Clases Conversacionales
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Row justify={"center"} className="cursos-divisor-title">
                            Clases
                        </Row>
                        <Row style={{ textAlign: "center" }} justify={"center"} className="cursos-divisor-subtitle">
                            por Lic. en Enseñanza del Inglés
                        </Row>
                    </Col>
                </Row>

                <Content justify={"center"} className="ml-2 mt-3 mr-2 mb-2" >


                    <Row justify={"center"}>
                        <Text className="landing-title-3">
                            ¿De qué va este curso?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Este es el curso perfecto para atacar la falta de práctica. Es una total inmersión al idioma que tiene la correcta constancia para aprovechar el conocimiento. Cada sesión atiende una función de la comunicación para que entiendas perfectamente lo que hay que decir en el momento adecuado, sin miedo, rápido y con la pronunciación correcta.
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Quién me enseña?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            Un verdadero especialista en la enseñanza de inglés, egresado de la Licenciatura en Enseñanza de Inglés (UNAM). <br />
                            Que hace que la clase sea dinámica y digerible para que puedas disfrutar de los beneficios reales con cada módulo que avanzas.

                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-3">
                            ¿Puede entrar cualquiera?
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-1">
                        <Text className="landing-content">
                            No. Este curso es para ti que ya puedes mantener una conversación básica pero te faltan palabras, no encuentras la correcta o hay un “algo” que a veces te frena de hablar con fluidez.
                        </Text>
                    </Row>

                    <Row justify={"center"} className="mt-2">
                        <Text className="landing-title-2">
                            HORARIOS DISPONIBLES
                        </Text>
                    </Row>
                    <Row justify={"center"} className="mt-2 mb-2">
                        <p style={{ textAlign: "center" }}>
                            Lunes, miércoles y jueves<br />
                            20:00 a 21:30 h<br />
                        </p>
                    </Row>

                    <Row justify={"center"}>
                        <a href="http://m.me/IngsalaMex?text=Quiero%20reservar%20mi%20lugar%20para%20curso%20speak-king" target="_blank" rel="noreferrer">
                            <Tag className="info-tag">
                                Reservar mi lugar
                            </Tag>
                        </a>

                    </Row>



                </Content>

                <Row justify={"center"}>
                    <Testimonios />
                </Row>

            </Layout>
        </>

    )

}



import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image } from "antd";
import React, { Component, useEffect, useState, useRef } from "react";
import { Content } from "antd/lib/layout/layout";


import { Link } from "react-router-dom";


import "../../../Styles/Modules/Examen.scss";
import { Cursos } from './examanes';
// import Link from "antd/lib/typography/Link";


const { Text, Paragraph, Title } = Typography;


export default function Landing(props) {

    return (
        <Layout className="examen start-examen" style={{ paddingTop: 100 }}>
            <Row className="mt-2" style={{ width: "100%" }}>
                <Card size="small" className="test-title-card">
                    <Row>
                        <Col md={24} lg={14}>
                            <Title className="test-title-card-title">Examen diagnóstico</Title>
                            <Paragraph className="test-title-card-subtitle">El siguiente examen nos permitirá conocer tus habilidades y nivel de inglés.</Paragraph>
                        </Col>
                        <Col md={24} lg={10}>
                            <img className="title-img" src="/img/examen/start.png" />
                        </Col>
                    </Row>
                </Card>
            </Row>
            <Content className="test-section description ">
                <Paragraph className="mt-1 mb-2">
                    Antes de apartar tu lugar o realizar la compra de uno de nuestros cursos, te sugerimos que realices el “examen diagnósito” que nos ayudará a colocarte en el nivel de inglés adecuado para ti.
                </Paragraph>
            </Content>
            {/* <Content className="test-section " /> */}
            <Row className="test-section">
                <Link to="/examen" style={{ display: "block", margin: "auto"}}>
                    <Button className="test-start-exam" size="large" type="primary">Quiero iniciar mi  examen</Button>
                </Link>
            </Row>
        </Layout>
    )
}



import { Footer, Header } from "antd/lib/layout/layout";
import React from "react";
import '../../Styles/Public/footer.scss';
import { Row, Col, Menu, Button, Typography, Space } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { BiCopyright } from "react-icons/bi";

const { Text } = Typography

export default function FooterPublic() {

    const navigate = useNavigate()

    return (
        <div className="footer-public">
            <Footer>

                <Row>

                    <Col xs={24} md={4} lg={4}>
                        <Row justify={"center"}>
                            <img src="/img/logo-white.png" />
                        </Row>
                        <Row justify={"center"} className="mt-1">
                            <Text className="location">
                                CDMX
                            </Text>
                        </Row>

                    </Col>

                    <Col xs={24} md={12} lg={12}>
                        <Row>
                            <Col xs={24} md={12}>
                                <ul className="footer-list">
                                    <li>
                                        <Text className="title">Inglés a la Mexicana</Text>
                                    </li>
                                    <li>
                                        <Link to={'/#about-us'} className="content">
                                            Conócenos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/#courses'} className="content">
                                            Cursos
                                        </Link>
                                    </li>

                                    <li>
                                        {/* <Button className="boton-claro mt-1">
                                            Examen Diágnostico
                                        </Button> */}
                                    </li>
                                </ul>

                            </Col>
                            <Col xs={24} md={10}>
                                <ul className="footer-list">
                                    <li>
                                        <Text className="title">Servicios</Text>
                                    </li>
                                    <li>
                                        <Link to={'/#courses'} className="content">
                                            Cursos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/cursos/empresas"}>
                                            <Text className="content">
                                                Empresas
                                            </Text>
                                        </Link>

                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} md={6} lg={6}>
                        <Row justify={"center"}>
                            <Text className="reserva">
                                Reserva tu lugar
                            </Text>
                        </Row>
                        <Row className="mt-2" gutter={[0, 20]} justify={"center"}>
                            <Col xs={8} md={8} lg={8}>
                                <Row justify={"center"}>
                                    <a href="https://www.facebook.com/IngsalaMex" target="_blank" rel="noreferrer">
                                        <img src="/img/fb-white.png" alt="fb" />
                                    </a>
                                </Row>
                            </Col>

                            <Col xs={8} md={8} lg={8}>
                                <Row justify={"center"}>
                                    <a href="https://www.instagram.com/ingsalamex/" target="_blank" rel="noreferrer">
                                        <img src="/img/ig-white.png" alt="ig" />
                                    </a>
                                </Row>
                            </Col>

                            <Col xs={8} md={8} lg={8}>
                                <Row justify={"center"}>
                                    <a href="https://www.tiktok.com/@inglesalamexicana?source=h5_m" target="_blank" rel="noreferrer">
                                        <img src="/img/tiktok-white.png" alt="tiktok" />
                                    </a>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </Row>



            </Footer>
            <Row className="copyright" justify={"center"}>
                <Text>
                    <BiCopyright />  2023 Inglés a la Mexicana
                </Text>
            </Row>
        </div>

    )
}
import { Header } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import '../../Styles/Public/header.scss';
import { Row, Col, Menu, Button } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import DrawerPublic from "./Drawer";


export default function HeaderPublic(props) {

    const navigate = useNavigate()
    const location = useLocation()

    const [drawerOpen, setDrawerOpen] = useState(false)

    const items = [
        {
            label: "Inicio",
            key: '/'
        },
        {
            label: "Conócenos",
            key: '/cursos/libera-tu-ingles'
        },
        {
            label: "Cursos",
            key: '/#courses'
        },
        {
            label: "Horarios",
            key: '/#schedules'
        }
    ]

    let handleClick = (e) => {

        navigate(e.key)

    }

    useEffect(() => {
        console.log("cambio de location", location.hash)
        if (document.getElementById(location.hash.replace("#", ""))) {
            let pos = document.getElementById(location.hash.replace("#", "")).getBoundingClientRect().top + window.pageYOffset - 60;
            window.scrollTo({ top: pos, behavior: "smooth" });
        }
        // document.getElementById(location.hash.replace("#",""))?.scrollIntoView({behavior:"smooth"})

        if (location.pathname === '/' && location.hash === '') window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [location])



    return (
        <div className="header-public">
            <Header style={props.color ? { backgroundColor: props.color } : {}}>
                <Row>
                    <Col xs={4}>
                        <Link to='/'>
                            <img className="logo-im" src={props.logo === "white" ? "/img/logo-white.png" : "/img/logo.png"} alt="logo" />
                        </Link>
                    </Col>
                    <Col xs={0} sm={12} md={13} lg={15}>
                        <Menu mode="horizontal" style={props.color ? { backgroundColor: props.color, color: props.logo } : {}} items={items} onClick={e => handleClick(e)} />
                    </Col>
                    <Col xs={10} sm={8} md={7} lg={5}>

                        <Link to="/examen-diagnostico">
                            <Button className="boton-claro">
                                Examen Diagnóstico
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={10} sm={0} md={0}>
                        <Row justify={"end"} align={"middle"} style={{ height: "100%" }}>
                            <Button onClick={() => setDrawerOpen(true)} style={{ color: "#459EFF", borderColor: "#459EFF" }}>
                                <MenuOutlined />
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Header>
            <DrawerPublic
                width={"85%"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            />
        </div>

    )
}
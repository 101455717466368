import { Layout, Row, Col, Typography, Button, Space, Tag, Card, Avatar, Image, Steps, Progress, Radio, message, } from "antd";
import React, { Component, useEffect, useState, useRef } from "react";
import { Content } from "antd/lib/layout/layout";
import '../../../Styles/Modules/Examen.scss';
import { Preguntas as CPreguntas } from './examanes';
import Link from "antd/lib/typography/Link";
import { CheckCircleFilled, CheckOutlined, FacebookOutlined, WhatsAppOutlined, } from "@ant-design/icons";

const { Text, Paragraph, Title } = Typography;

function comparacionAleatoria() {
    return Math.random() - 0.5;
}

const Preguntas = CPreguntas.map(({ respuestas, ...rest }) => ({ ...rest, respuestas: respuestas.sort(comparacionAleatoria) }))

export default function Landing(props) {

    let [preguntas, setPreguntas] = useState(Preguntas)
    let [pregunta, setPregunta] = useState(0)
    let [resultados, setResultados] = useState(0)


    // console.log('preguntas', preguntas);

    // window.x = preguntas
    // {}/{preguntas.length }git 
    const renderUrl = (baseUrl = "https://api.whatsapp.com/send?phone=525585369612") => {
        let url = new URL(baseUrl)
        url.searchParams.append("text", `¡Hola! Me gustaría obtener ínformación de los cursos. He concluido mi examen de diagnóstico con ${resultados} de ${preguntas.length}`)
        return url.href

    }
    // resultados, setResultados
    return (
        <Layout className="examen" style={{ paddingTop: 100 }}>
            <Row style={{ width: "100%" }}>
                <Card size="small" className="test-title-card">
                    <Row>
                        <Col md={24} lg={14}>
                            <Title className="test-title-card-title">Examen diagnóstico</Title>
                            <Paragraph className="test-title-card-subtitle">El siguiente examen nos permitirá conocer tus habilidades y nivel de inglés.</Paragraph>
                        </Col>
                        <Col md={24} lg={10}>
                            <img className="title-img" src="/img/examen/start.png" />
                        </Col>
                    </Row>
                </Card>
            </Row>
            {!(pregunta <= 30) && <Row className="test-section mt-1 mb-1" >
                <Title level={2} style={{ textAlign: "center", width: "100%" }}>Pregunta {pregunta + 1} de  {preguntas.length }</Title>
                <Progress percent={pregunta / (preguntas.length - 1) * 100} showInfo={false} />
            </Row>}
            {(pregunta == preguntas.length) ? <Card className="test-section preguntas-container mt-1 mb-3" >
                <Title className="mb-2" level={2} style={{ width: "100%", textAlign: "center" }}>Resultados</Title>
                <Paragraph style={{ width: "100%", textAlign: "center" }}>Has completado tu examen diagnósito a continuación, visualiza tus resultados.</Paragraph>
                <Card style={{ borderRadius: 30 }}>
                    <svg className="mb-1" width="163" height="146" viewBox="0 0 163 146" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: "block", margin: "auto" }}>
                        <g clip-path="url(#clip0_1269_1390)">
                            <path d="M163 105.12H0V11.68C0 5.2268 5.21018 0 11.6429 0H151.357C157.79 0 163 5.2268 163 11.68V105.12Z" fill="#353744" />
                            <path d="M163 105.12H0V110.96C0 117.413 5.21018 122.64 11.6429 122.64H151.357C157.79 122.64 163 117.413 163 110.96V105.12Z" fill="#808080" />
                            <path d="M151.357 105.12H0V11.68C0 5.2268 5.21018 0 11.6429 0H139.714C146.147 0 151.357 5.2268 151.357 11.68V105.12Z" fill="#55525B" />
                            <path d="M151.357 105.12H0V110.96C0 117.413 5.21018 122.64 11.6429 122.64H139.714C146.147 122.64 151.357 117.413 151.357 110.96V105.12Z" fill="#DBDEE3" />
                            <path d="M135.348 11.68H16.0091C13.5932 11.68 11.6431 13.6364 11.6431 16.06V89.06C11.6431 91.4836 13.5932 93.44 16.0091 93.44H135.348C137.764 93.44 139.715 91.4836 139.715 89.06V16.06C139.715 13.6364 137.764 11.68 135.348 11.68Z" fill="#ACD4F8" />
                            <path d="M29.1071 35.04H93.1428C96.3446 35.04 98.9642 32.412 98.9642 29.2C98.9642 25.988 96.3446 23.36 93.1428 23.36H29.1071C25.9053 23.36 23.2856 25.988 23.2856 29.2C23.2856 32.412 25.9053 35.04 29.1071 35.04Z" fill="#459EFF" />
                            <path d="M29.1071 58.4H93.1428C96.3446 58.4 98.9642 55.772 98.9642 52.56C98.9642 49.348 96.3446 46.72 93.1428 46.72H29.1071C25.9053 46.72 23.2856 49.348 23.2856 52.56C23.2856 55.772 25.9053 58.4 29.1071 58.4Z" fill="#459EFF" />
                            <path d="M29.1071 81.76H93.1428C96.3446 81.76 98.9642 79.132 98.9642 75.92C98.9642 72.708 96.3446 70.08 93.1428 70.08H29.1071C25.9053 70.08 23.2856 72.708 23.2856 75.92C23.2856 79.132 25.9053 81.76 29.1071 81.76Z" fill="#459EFF" />
                            <path d="M91.6875 134.32H103.33V122.64H91.6875V134.32Z" fill="#808080" />
                            <path d="M91.6874 122.64H64.0356V134.32H91.6874V122.64Z" fill="#B3B4B6" />
                            <path d="M114.973 134.32H42.2054C35.7727 134.32 30.5625 139.547 30.5625 146H126.616C126.616 139.547 121.406 134.32 114.973 134.32Z" fill="#DBDEE3" />
                            <path d="M114.973 134.32H103.33C96.8977 134.32 91.6875 139.547 91.6875 146H126.616C126.616 139.547 121.406 134.32 114.973 134.32Z" fill="#808080" />
                            <path d="M119.339 81.76C116.137 81.76 113.518 79.132 113.518 75.92C113.518 72.708 116.137 70.08 119.339 70.08C122.541 70.08 125.161 72.708 125.161 75.92C125.161 79.132 122.541 81.76 119.339 81.76Z" fill="#F0634E" />
                            <path d="M117.156 25.55L119.339 27.74L125.889 21.17C127.082 19.9728 129.061 19.9728 130.255 21.17C131.448 22.3672 131.448 24.3528 130.255 25.55L121.522 34.31C120.329 35.5072 118.35 35.5072 117.156 34.31L112.79 29.93C111.597 28.7328 111.597 26.7472 112.79 25.55C113.984 24.3528 115.963 24.3528 117.156 25.55Z" fill="#2BD073" />
                            <path d="M117.156 48.91L119.339 51.1L125.889 44.53C127.082 43.3328 129.061 43.3328 130.255 44.53C131.448 45.7272 131.448 47.7128 130.255 48.91L121.522 57.67C120.329 58.8672 118.35 58.8672 117.156 57.67L112.79 53.29C111.597 52.0928 111.597 50.1072 112.79 48.91C113.984 47.7128 115.963 47.7128 117.156 48.91Z" fill="#3FBD98" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1269_1390">
                                <rect width="163" height="146" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <Title style={{ textAlign: "center" }}>{resultados}/{preguntas.length }</Title>
                    {/* <Paragraph style={{ textAlign: "center", fontSize: 16 }}>De acuerdo a tu resultado, los cursos sugeridos son los siguientes: </Paragraph> */}
                    <Paragraph style={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}>¡Gracias por completar el examen!</Paragraph>
                    <Paragraph style={{ textAlign: "center", fontSize: 16 }}>
                        Selecciona en los botones de abajo el canal por el que te están atendiendo y nos llegará tu resultado. 
                    </Paragraph>
                    <Paragraph style={{ textAlign: "center", fontSize: 16 }}>
                        Así sabremos el curso perfecto para ti.
                    </Paragraph>
                    <Paragraph style={{ textAlign: "center", fontSize: 36 }}>
                        🙌🏼🙌🏼
                    </Paragraph>
                    <Row className="test-section mt-2" style={{ margin: "auto", display: "block" }} clas>
                        <a
                            target="_blank"
                            href={renderUrl()}
                            style={{ margin: "auto", display: "block" }}
                        >
                            <Button className="test-start-exam" size="large" type="primary" style={{ margin: "auto", display: "block", maxWidth: "100%" }} >¡Quiero que me contacten!</Button>
                        </a>
                    </Row>
                    {/* style={{ margin: "auto", display: "block" }} clas */}
                    <Row className="mt-2">
                        <Col span={12} >
                            <a
                                target="_blank"
                                href={renderUrl('https://www.messenger.com/t/100064782699993/')}
                            >
                                <FacebookOutlined style={{ display: "block", margin: "auto", fontSize: 30 }} />
                                <span style={{ textAlign: "center", display: "block", margin: "auto", marginTop: 8, fontSize: 16, fontWeight: "bold" }}>
                                    Por Messenger
                                </span>
                            </a>
                        </Col>
                        <Col span={12}>
                            <a
                                target="_blank"
                                href={renderUrl()}
                            >
                                <WhatsAppOutlined style={{ display: "block", margin: "auto", fontSize: 30 }} />
                                <span style={{ textAlign: "center", display: "block", margin: "auto", marginTop: 8, fontSize: 16, fontWeight: "bold" }}>
                                    Por WhatsApp
                                </span>
                                {/* <Paragraph>Por WhatsApp</Paragraph> */}
                            </a>
                        </Col>
                    </Row>
                </Card>
            </Card> : <Card className="test-section preguntas-container mt-1 mb-3" >
                <Title className="mb-2 " level={2} style={{ width: "100%", }}>
                    <small>{pregunta + 1})</small> {preguntas[pregunta].respondio ? preguntas[pregunta].pregunta.replace(/_+/g, '{{answer}}').split(/({{answer}})/).filter(item => item !== '').map(e => e.match('{{answer}}') ? <i style={{ textDecoration: "underline", color: "#1051C5" }}>{preguntas[pregunta].respondio}</i> : e) : preguntas[pregunta].pregunta}
                </Title>
                <Radio.Group
                    className="preguntas"
                    value={preguntas[pregunta].respondio}
                    onChange={event => {
                        let preguntasTemp = preguntas
                        preguntasTemp[pregunta].respondio = event.target.value
                        setPreguntas([...preguntasTemp])
                        setResultados(preguntasTemp.reduce((contador, pregunta) => contador + ((
                            pregunta.respuesta == pregunta.respondio
                        ) ? 1 : 0), 0))
                    }}
                >
                    {preguntas[pregunta].respuestas.map((e, index) => <Row className="preguntas-item" style={{ marginBottom: 20 }}>
                        <Radio.Button className="preguntas-item-button" value={e}>
                            {String.fromCharCode((index + 1) + 64).toLocaleLowerCase()}) {e}
                        </Radio.Button>
                    </Row>)}
                </Radio.Group>
                <Button
                    type="primary"
                    style={{ float: "right" }}
                    onClick={() => {
                        if (!preguntas[pregunta].respondio)
                            return message.error("Debe seleccionar una respuesta")
                        setPregunta(pregunta + 1)
                    }}
                >Siguiente</Button>
            </Card>}
            {/* {pregunta + 1} {preguntas.length } */}
        </Layout>
    )
}


